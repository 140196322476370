import { UnitOfMeasureType } from './api-shared.enums';

export interface InformationRequestBase {
  CustomerId: string;
  ProductKey: string;
  ProductNumber: string;
  ProductDescription: string;
  ProductBrand: string;
}

export interface ReportIncorrectProductInformation extends InformationRequestBase {
  IncorrectReason: string;
  ContactEmailAddress: string;
}

export interface RequestProductNutritionalInformation extends InformationRequestBase {
  RequestDetails: string;
  ContactEmailAddress: string;
}

export interface AdditionalInformation {
  WholeGrainRich?: string;
  LowSodium?: string;
  ReducedSodium?: string;
  LactoseFree?: string;
  Organic?: string;
  Vegan?: string;
  Halal?: string;
  MSCCertified?: string;
  GeneticallyModified?: string;
  Kosher?: string;
  ChildNutrition?: string;
}

export interface Allergens {
  Egg?: string;
  Milk?: string;
  Peanut?: string;
  PeanutFacility?: string;
  Sesame?: string;
  TreeNut?: string;
  Soy?: string;
  Wheat?: string;
  Crustacean?: string;
  Fish?: string;
  GlutenFree?: string;
  GlutenFacility?: string;
}

export interface GetProductDetailDocumentHeadersRequest {
  BusinessUnitKey: number;
  BusinessUnitERPKey: number;
  ProductKey: string;
}

export interface GetProductDetailDocumentRequest {
  DocumentKey: string;
  BusinessUnitKey: number;
}
export interface ProductDetailDocument {
  DocumentKey: string;
  DocumentContentType: string;
  DocumentName: string;
}

export interface GetProductDetailPdfRequest {
  ProductKey: string;
  CustomerId: string;
  OrderEntryHeaderId?: string;
}

export interface PackingInformation {
  UnitPerCase: number;
  ServingsPerCase: number;
  UnitSize: number;
  GrossWeight: number;
  GrossWeightMeasureAbbreviation?: string;
  NetWeight: number;
  NetWeightMeasureAbbreviation?: string;
  PalletTiHiDisplay?: string;
}

export interface ProductInformationAllergensAndAdditional {
  Allergens: Allergens;
  AdditionalInformation: AdditionalInformation;
  SchoolEquivalents: SchoolEquivalents;
  AdditionalDescription?: string;
}

export interface ProductInformationDescription {
  SalesDescription?: string;
  BenefitsMarketingClaims?: string;
  Ingredients?: string;
  ManufactureNumber?: string;
  GTIN?: string;
  CSBarcode?: string;
  BXBarcode?: string;
  EABarcode?: string;
  Class?: string;
  PBH?: string;
  Origin?: string;
  NutritionalFactsImageURL?: string;
}

export interface ProductInformationPackingAndStorage {
  PackingInformation: PackingInformation;
  Storage: Storage;
  StorageMethodDescription?: string;
}

export interface SchoolEquivalents {
  ServingSize?: string;
  Nut?: string;
  MeatMeatAlternatives?: string;
  FruitVegetables?: string;
  GrainBread?: string;
  BeansPeasLegumes?: string;
  Bread?: string;
  DarkGreenVeg?: string;
  Milk?: string;
  Fruit?: string;
  FruitVeggie?: string;
  Grains?: string;
  Meat?: string;
  OtherVeg?: string;
  RedOrangeVeg?: string;
  StarchyVeg?: string;
}

export interface Storage {
  Length: number;
  LengthMeasureAbbreviation?: string;
  Width: number;
  WidthMeasureAbbreviation?: string;
  Height: number;
  HeightMeasureAbbreviation?: string;
  ShelfLifeDays: number;
  StorageTempMax: number;
  StorageTempMin: number;
  IsCatchWeight: boolean;
}

export interface GetProductDetailSalesRequest {
  ProductToken: string;
}

export interface GetProductDetailRequest {
  CustomerId: string;
  ProductKey: string;
  OrderEntryHeaderId?: string;
}

export interface ProductDetailRequest {
  BusinessUnitKey: number;
  BusinessUnitERPKey: number;
  ProductKey: string;
}

export interface ProductDetailUpdateQuantityState {
  initialQuantity: number;
  updatedQuantity?: number;
  isChanged: boolean;
}

export interface ProductDetailTransfer {
  productKey: string;
  uom: UnitOfMeasureType;
  extendedPrice: number;
  quantity: number;
  showOrderQuantityAlert: boolean;
}
export interface SimilarProductFilter {
  productKey: string;
  searchFilter: string;
}

export interface GetUSDAProductDetailsRequest {
  CustomerId: string;
  ProductKeys: string[];
}

export interface USDAProductDetails {
  CustomerId: string;
  USDAProductDetails: USDAProductDetail[];
}

export interface USDAProductDetail {
  ProductKey: string;
  AllocatedQty?: number;
  RemainingQty?: number;
  AllocatedDollar?: number;
  RemainingDollar?: number;
  AllocatedWeightLbs?: number;
  RemainingWeightLbs?: number;
  AllocatedPerCaseDollar?: number;
  RemainingPerCaseDollar?: number;
  USDAType: number;
  LastUpdatedDate: string | Date;
}
export type GetProductInformationAllergensAndAdditionalRequest = ProductDetailRequest;
export type GetProductInformationDescriptionRequest = ProductDetailRequest;
export type GetProductInformationPackingAndStorageRequest = ProductDetailRequest;

export enum USDAProductTypeEnum {
  Quantity = 0,
  Dollar = 1,
  WeightInLbs = 2,
  PerCaseDollar = 3,
}
