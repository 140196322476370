import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { FileResult } from '../../api';
import ProductDetailSalesService from '../../api/services/product-detail-sales.service';
import { useAppInsightsLogger } from '../../logging';
import { globalSlice } from '../common';
import { AppDispatch, AppThunk, RootState } from '../store';
import { productDetailSlice } from './product-detail.slice';
import { setProductDetailPdfIsLoading } from './product-detail.thunks';

//hooks
const productDetailSalesService = ProductDetailSalesService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Calls and stores results from GetProductDetail, GetProductFooter, and GetProductInformationDescription API calls
 *
 * @param productToken - The product who's details are being retrieved
 * @returns NULL
 */
export const getProductDetailSales =
  (productToken: string, successCallback?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      if (!getState().productDetail.productDetailLoading)
        dispatch(productDetailSlice.actions.setProductDetailLoading(true));

      dispatch(productDetailSlice.actions.setLoadingProductDetail(true));

      const request = {
        ProductToken: productToken,
      };

      const getDetailTask = productDetailSalesService.getProductDetail(request);
      const getInfoTask = dispatch(getProductInformationSalesDescription(request.ProductToken));
      const result = await Promise.all([getDetailTask, getInfoTask]);

      if (result[0].data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductDetail(result[0].data.ResultObject));
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: result[0].data.ErrorMessages }));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    } finally {
      dispatch(productDetailSlice.actions.setProductDetailLoading(false));
      dispatch(productDetailSlice.actions.setLoadingProductDetail(false));
    }
  };

export const getProductInformationSalesDescription =
  (productToken: string): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const previousRequest = getState().productDetail.productInformationDescriptionRequest;
      const request = {
        ProductToken: productToken,
      };

      if (JSON.stringify(previousRequest) === JSON.stringify(request)) {
        return;
      }

      const { data } = await productDetailSalesService.getProductInformationDescription(request);

      if (data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductInformationDescription(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const getProductInformationSalesAllergensAndAdditional =
  (productToken: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const previousRequest = getState().productDetail.productInformationAllergensAndAdditionalRequest;
      const request = {
        ProductToken: productToken,
      };

      if (JSON.stringify(previousRequest) === JSON.stringify(request)) {
        return;
      }

      const { data } = await productDetailSalesService.getProductInformationAllergensAndAdditional(request);

      if (data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductInformationAllergensAndAdditional(data.ResultObject));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const getProductInformationSalesPackingAndStorage =
  (productToken: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const previousRequest = getState().productDetail.productInformationPackingAndStorageRequest;
      const request = {
        ProductToken: productToken,
      };

      if (JSON.stringify(previousRequest) === JSON.stringify(request)) {
        return;
      }

      dispatch(productDetailSlice.actions.setLoadingProductInformationPackingAndStorage(true));

      const { data } = await productDetailSalesService.getProductInformationPackingAndStorage(request);

      if (data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductInformationPackingAndStorage(data.ResultObject));
      }
      dispatch(productDetailSlice.actions.setLoadingProductInformationPackingAndStorage(false));
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const getProductDetailSalesReport =
  (productToken: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const previousRequest = getState().productDetail.productDetailPdfRequest;
      const request = {
        ProductToken: productToken,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (JSON.stringify(previousRequest) === JSON.stringify(request)) {
        return;
      }

      dispatch(setProductDetailPdfIsLoading(true));

      const { data } = await productDetailSalesService.getProductDetailReport(request);
      if (data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductDetailPdf(data.ResultObject as FileResult));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(setProductDetailPdfIsLoading(false));
    }
  };
