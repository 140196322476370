import { BusinessUnitKeyType, ProductBadgeType } from '../../api/models/api-shared.enums';

export class ProductBadgeLookup {
  public static ProductBadgeLookup: { [key in BusinessUnitKeyType]: ProductBadgeReference[] } = {
    [BusinessUnitKeyType.PerformanceFoodService]: [
      { BadgeType: ProductBadgeType.EarlyCutoff, BadgeIcon: 'fa-alarm-clock' },
      { BadgeType: ProductBadgeType.CriticalItem, BadgeIcon: 'fa-star' },
      { BadgeType: ProductBadgeType.IsHidden, BadgeIcon: 'fa-eye-slash' },
      { BadgeType: ProductBadgeType.IsRetained, BadgeIcon: 'fa-thumbtack' },
      //{ BadgeType: ProductBadgeType.OnManagedList, BadgeIcon: 'fa-list-alt' },
      { BadgeType: ProductBadgeType.MSDS, BadgeIcon: 'fa-flask' },
      { BadgeType: ProductBadgeType.SpecialOrder, BadgeIcon: 'fa-phone' },
      { BadgeType: ProductBadgeType.DirectShip, BadgeIcon: 'fa-phone' },
      { BadgeType: ProductBadgeType.VirtualWarehouse, BadgeIcon: 'fa-box-open' },
      { BadgeType: ProductBadgeType.IsTemporarilyOut, BadgeIcon: 'fa-ban' },
      { BadgeType: ProductBadgeType.USDAProduct, BadgeIcon: 'fa-solid fa-farm' },
      //{ BadgeType: ProductBadgeType.HasSubstituteProducts, BadgeIcon: 'fa-exchange' },
    ],
    [BusinessUnitKeyType.Vistar]: [
      { BadgeType: ProductBadgeType.EarlyCutoff, BadgeIcon: 'fa-alarm-clock' },
      { BadgeType: ProductBadgeType.CriticalItem, BadgeIcon: 'fa-star' },
      { BadgeType: ProductBadgeType.IsHidden, BadgeIcon: 'fa-eye-slash' },
      { BadgeType: ProductBadgeType.IsRetained, BadgeIcon: 'fa-thumbtack' },
      //{ BadgeType: ProductBadgeType.OnManagedList, BadgeIcon: 'fa-list-alt' },
      { BadgeType: ProductBadgeType.MSDS, BadgeIcon: 'fa-flask' },
      { BadgeType: ProductBadgeType.SpecialOrder, BadgeIcon: 'fa-phone' },
      { BadgeType: ProductBadgeType.IsTemporarilyOut, BadgeIcon: 'fa-ban' },
      { BadgeType: ProductBadgeType.USDAProduct, BadgeIcon: 'fa-solid fa-farm' },
      //{ BadgeType: ProductBadgeType.HasSubstituteProducts, BadgeIcon: 'fa-exchange' },
    ],
    [BusinessUnitKeyType.PerformanceFoodGroupCustomized]: [
      { BadgeType: ProductBadgeType.EarlyCutoff, BadgeIcon: 'fa-alarm-clock' },
      { BadgeType: ProductBadgeType.CriticalItem, BadgeIcon: 'fa-star' },
      { BadgeType: ProductBadgeType.IsHidden, BadgeIcon: 'fa-eye-slash' },
      { BadgeType: ProductBadgeType.IsRetained, BadgeIcon: 'fa-thumbtack' },
      //{ BadgeType: ProductBadgeType.OnManagedList, BadgeIcon: 'fa-list-alt' },
      { BadgeType: ProductBadgeType.MSDS, BadgeIcon: 'fa-flask' },
      { BadgeType: ProductBadgeType.IsTemporarilyOut, BadgeIcon: 'fa-ban' },
      { BadgeType: ProductBadgeType.USDAProduct, BadgeIcon: 'fa-solid fa-farm' },
      //{ BadgeType: ProductBadgeType.HasSubstituteProducts, BadgeIcon: 'fa-exchange' },
    ],
    [BusinessUnitKeyType.ReinhartFoodService]: [
      { BadgeType: ProductBadgeType.EarlyCutoff, BadgeIcon: 'fa-alarm-clock' },
      { BadgeType: ProductBadgeType.CriticalItem, BadgeIcon: 'fa-star' },
      { BadgeType: ProductBadgeType.IsHidden, BadgeIcon: 'fa-eye-slash' },
      { BadgeType: ProductBadgeType.IsRetained, BadgeIcon: 'fa-thumbtack' },
      //{ BadgeType: ProductBadgeType.OnManagedList, BadgeIcon: 'fa-list-alt' },
      { BadgeType: ProductBadgeType.MSDS, BadgeIcon: 'fa-flask' },
      { BadgeType: ProductBadgeType.ShipsLater, BadgeIcon: 'fa-hourglass-half' },
      { BadgeType: ProductBadgeType.DirectShip, BadgeIcon: 'fa-box-open' },
      { BadgeType: ProductBadgeType.IsTemporarilyOut, BadgeIcon: 'fa-ban' },
      { BadgeType: ProductBadgeType.USDAProduct, BadgeIcon: 'fa-solid fa-farm' },
      //{ BadgeType: ProductBadgeType.HasSubstituteProducts, BadgeIcon: 'fa-exchange' },
    ],
    [BusinessUnitKeyType.Afflink]: [
      { BadgeType: ProductBadgeType.EarlyCutoff, BadgeIcon: 'fa-alarm-clock' },
      { BadgeType: ProductBadgeType.CriticalItem, BadgeIcon: 'fa-star' },
      { BadgeType: ProductBadgeType.IsHidden, BadgeIcon: 'fa-eye-slash' },
      { BadgeType: ProductBadgeType.IsRetained, BadgeIcon: 'fa-thumbtack' },
      // { BadgeType: ProductBadgeType.OnManagedList, BadgeIcon: 'fa-list-alt' },
      { BadgeType: ProductBadgeType.MSDS, BadgeIcon: 'fa-flask' },
      { BadgeType: ProductBadgeType.IsTemporarilyOut, BadgeIcon: 'fa-ban' },
      { BadgeType: ProductBadgeType.USDAProduct, BadgeIcon: 'fa-solid fa-farm' },
      //{ BadgeType: ProductBadgeType.HasSubstituteProducts, BadgeIcon: 'fa-exchange' },
    ],
    [BusinessUnitKeyType.EbyBrown]: [
      { BadgeType: ProductBadgeType.EarlyCutoff, BadgeIcon: 'fa-alarm-clock' },
      { BadgeType: ProductBadgeType.CriticalItem, BadgeIcon: 'fa-star' },
      { BadgeType: ProductBadgeType.IsHidden, BadgeIcon: 'fa-eye-slash' },
      { BadgeType: ProductBadgeType.IsRetained, BadgeIcon: 'fa-thumbtack' },
      //{ BadgeType: ProductBadgeType.OnManagedList, BadgeIcon: 'fa-list-alt' },
      { BadgeType: ProductBadgeType.MSDS, BadgeIcon: 'fa-flask' },
      { BadgeType: ProductBadgeType.IsTemporarilyOut, BadgeIcon: 'fa-ban' },
      { BadgeType: ProductBadgeType.USDAProduct, BadgeIcon: 'fa-solid fa-farm' },
      //{ BadgeType: ProductBadgeType.HasSubstituteProducts, BadgeIcon: 'fa-exchange' },
    ],
  };
}
export interface ProductBadgeReference {
  BadgeType: ProductBadgeType;
  BadgeIcon: string;
}
