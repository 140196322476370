import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { ProductInventory } from '../../api';
import {
  GetActiveOrderResult,
  GetConfirmationOrderEntryHeadersRequest,
  OrderEntryHeader,
} from '../../api/models/order-entry-header.models';
import { GetOrderResultData, OrderCart, OrderHeader } from '../../api/models/order.models';
import { IsLowInventory } from '../../api/models/product-footer.enum';
import {
  GetSubmittedOrderHeadersRequest,
  GetSubmittedOrderRequest,
  OpenOrderHeadersResult,
  SubmittedOrderDetail,
} from '../../api/models/submitted-order.models';
import { getLowInventoryUoms, getProductIsLowInventory } from '../../helpers/general/product';
import { ProductData } from '../../models';
import { RootState } from '../store';
import { OrdersPageQueryParams } from './routing.models';

const productInventoryProductKeyAdapter = createEntityAdapter<string>({
  selectId: (productKey: string) => productKey,
});

const productInventoryAdapter = createEntityAdapter<ProductInventory>({
  selectId: (o: ProductInventory) => `${o.ProductKey}-${o.UnitOfMeasureType}`,
});

// State
export interface OrdersState {
  submittedOrdersApiRequest?: GetSubmittedOrderHeadersRequest;
  confirmationsApiRequest?: GetConfirmationOrderEntryHeadersRequest;
  activeOrder?: GetActiveOrderResult;
  order?: GetOrderResultData;
  orderCart: OrderCart | undefined;
  cartLoading: boolean;
  updatedDeliveryDate?: string;
  recentOrders: OrderHeader[];
  recentOrdersLoading: boolean;
  orderDetailsUpdating: boolean;
  orderConfirmationLoading: boolean;
  productPriceLoading: boolean;
  customerOrderEntryHeaders: OrderEntryHeader[];
  confirmationOrderEntryHeaders: OrderEntryHeader[];
  selectedSubmittedOrderHeader: OpenOrderHeadersResult | undefined;
  selectedSubmittedOrderDetails: SubmittedOrderDetail[];
  submittedOrderHeaders: OpenOrderHeadersResult[];
  loadingCustomerOrderEntryHeaders: boolean;
  loadingConfirmationOrderEntryHeaders: boolean;
  loadingSubmittedOrderHeaders: boolean;
  loadingSelectedSubmittedOrderHeader: boolean;
  loadingSelectedSubmittedOrderDetails: boolean;
  ordersPageQueryParams?: OrdersPageQueryParams;
  activeOrderIsFutureSubmission?: boolean;
  submittedOrdersDetailApiRequest?: GetSubmittedOrderRequest;
  isProductPricesLoaded?: boolean;
  productInventoryData: EntityState<ProductInventory>;
  productInventoryProductKeys: EntityState<string>;
}

const initialState: OrdersState = {
  submittedOrdersApiRequest: undefined,
  confirmationsApiRequest: undefined,
  activeOrder: undefined,
  order: undefined,
  orderCart: undefined,
  cartLoading: true,
  updatedDeliveryDate: undefined,
  recentOrders: [],
  recentOrdersLoading: true,
  orderDetailsUpdating: false,
  orderConfirmationLoading: false,
  productPriceLoading: false,
  customerOrderEntryHeaders: [],
  confirmationOrderEntryHeaders: [],
  selectedSubmittedOrderHeader: undefined,
  selectedSubmittedOrderDetails: [],
  submittedOrderHeaders: [],
  loadingCustomerOrderEntryHeaders: false,
  loadingConfirmationOrderEntryHeaders: false,
  loadingSubmittedOrderHeaders: false,
  loadingSelectedSubmittedOrderHeader: true,
  loadingSelectedSubmittedOrderDetails: true,
  ordersPageQueryParams: undefined,
  activeOrderIsFutureSubmission: false,
  submittedOrdersDetailApiRequest: undefined,
  isProductPricesLoaded: undefined,
  productInventoryData: productInventoryAdapter.getInitialState(),
  productInventoryProductKeys: productInventoryProductKeyAdapter.getInitialState(),
};

// Reducers
export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialState,
  reducers: {
    setOrdersState: (_state: OrdersState, action: PayloadAction<OrdersState>) => {
      return action.payload;
    },
    setActiveOrder: (state: OrdersState, action: PayloadAction<GetActiveOrderResult | undefined>) => {
      state.activeOrder = action.payload;
    },
    setCurrentOrder: (state: OrdersState, action: PayloadAction<GetOrderResultData | undefined>) => {
      state.order = action.payload;
    },
    setOrderCart: (state: OrdersState, action: PayloadAction<OrderCart | undefined>) => {
      state.orderCart = action.payload;
    },
    updateCurrentOrderEntryHeaderName: (state: OrdersState, action: PayloadAction<string>) => {
      if (state.order) state.order.OrderEntryHeaderName = action.payload;
    },
    updatePurchaseOrderNumber: (state: OrdersState, action: PayloadAction<string>) => {
      if (state.order) state.order.PurchaseOrderNumber = action.payload;
    },
    updateDeliveryDate: (state: OrdersState, action: PayloadAction<string>) => {
      if (state.order) state.order.DeliveryDate = action.payload;
    },
    setRecentOrders: (state: OrdersState, action: PayloadAction<OrderHeader[]>) => {
      state.recentOrders = action.payload;
    },
    setRecentOrdersLoading: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.recentOrdersLoading = action.payload;
    },
    setOrderDetailsUpdating: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.orderDetailsUpdating = action.payload;
    },
    setOrderConfirmationLoading: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.orderConfirmationLoading = action.payload;
    },
    setCartLoading: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.cartLoading = action.payload;
    },
    deleteOrder: (state: OrdersState, action: PayloadAction<string>) => {
      const orderIndex = state.recentOrders.findIndex((order) => order.OrderId === action.payload);
      if (orderIndex > -1) state.recentOrders.splice(orderIndex, 1);
    },
    setProductPriceLoading: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.productPriceLoading = action.payload;
    },
    setCustomerOrderEntryHeaders: (state: OrdersState, action: PayloadAction<OrderEntryHeader[]>) => {
      state.customerOrderEntryHeaders = action.payload;
    },
    resetCustomerOrderEntryHeaders: (state: OrdersState) => {
      state.customerOrderEntryHeaders = initialState.customerOrderEntryHeaders;
    },
    setLoadingCustomerOrderEntryHeaders: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.loadingCustomerOrderEntryHeaders = action.payload;
    },
    setConfirmationOrderEntryHeaders: (state: OrdersState, action: PayloadAction<OrderEntryHeader[]>) => {
      state.confirmationOrderEntryHeaders = action.payload;
    },
    setConfirmationsApiRequest: (
      state: OrdersState,
      action: PayloadAction<GetConfirmationOrderEntryHeadersRequest | undefined>
    ) => {
      state.confirmationsApiRequest = action.payload;
      if (!action.payload) {
        state.confirmationOrderEntryHeaders = initialState.confirmationOrderEntryHeaders;
      }
    },
    resetConfirmationOrderEntryHeaders: (state: OrdersState) => {
      state.confirmationOrderEntryHeaders = initialState.confirmationOrderEntryHeaders;
      state.confirmationsApiRequest = initialState.confirmationsApiRequest;
    },
    setLoadingConfirmationOrderEntryHeaders: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.loadingConfirmationOrderEntryHeaders = action.payload;
    },
    setSubmittedOrderHeaders: (state: OrdersState, action: PayloadAction<OpenOrderHeadersResult[]>) => {
      state.submittedOrderHeaders = action.payload;
    },
    setSubmittedOrdersApiRequest: (
      state: OrdersState,
      action: PayloadAction<GetSubmittedOrderHeadersRequest | undefined>
    ) => {
      state.submittedOrdersApiRequest = action.payload;
      if (!action.payload) {
        state.submittedOrdersApiRequest = initialState.submittedOrdersApiRequest;
      }
    },
    resetSubmittedOrderHeaders: (state: OrdersState) => {
      state.submittedOrderHeaders = initialState.submittedOrderHeaders;
      state.selectedSubmittedOrderHeader = initialState.selectedSubmittedOrderHeader;
      state.selectedSubmittedOrderDetails = initialState.selectedSubmittedOrderDetails;
      state.loadingSelectedSubmittedOrderDetails = initialState.loadingSelectedSubmittedOrderDetails;
      state.submittedOrdersApiRequest = initialState.submittedOrdersApiRequest;
      state.submittedOrdersDetailApiRequest = initialState.submittedOrdersDetailApiRequest;
    },
    resetSubmittedOrderDetails: (state: OrdersState) => {
      state.selectedSubmittedOrderHeader = initialState.selectedSubmittedOrderHeader;
      state.selectedSubmittedOrderDetails = initialState.selectedSubmittedOrderDetails;
      state.submittedOrdersDetailApiRequest = initialState.submittedOrdersDetailApiRequest;
      state.loadingSelectedSubmittedOrderDetails = initialState.loadingSelectedSubmittedOrderDetails;
    },
    setLoadingSubmittedOrderHeaders: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.loadingSubmittedOrderHeaders = action.payload;
    },
    resetSelectedSubmittedOrderHeader: (state: OrdersState) => {
      state.selectedSubmittedOrderHeader = undefined;
    },
    setSelectedSubmittedOrderHeader: (state: OrdersState, action: PayloadAction<OpenOrderHeadersResult>) => {
      state.selectedSubmittedOrderHeader = action.payload;
    },
    setSelectedSubmittedOrderDetails: (state: OrdersState, action: PayloadAction<SubmittedOrderDetail[]>) => {
      state.selectedSubmittedOrderDetails = action.payload;
    },
    setSelectedSubmittedOrderHeaderLoading: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.loadingSelectedSubmittedOrderHeader = action.payload;
    },
    setSelectedSubmittedOrderDetailsLoading: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.loadingSelectedSubmittedOrderDetails = action.payload;
    },
    setOrdersPageQueryParams: (state: OrdersState, action: PayloadAction<OrdersPageQueryParams>) => {
      state.ordersPageQueryParams = action.payload;
    },
    setSubmittedOrdersDetailApiRequest: (state: OrdersState, action: PayloadAction<GetSubmittedOrderRequest>) => {
      state.submittedOrdersDetailApiRequest = action.payload;
    },
    setActiveOrderIsFutureSubmission: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.activeOrderIsFutureSubmission = action.payload;
    },
    setProductPriceLoaded: (state: OrdersState, action: PayloadAction<boolean>) => {
      state.isProductPricesLoaded = action.payload;
    },
    resetProductPriceLoaded: (state: OrdersState) => {
      state.isProductPricesLoaded = initialState.isProductPricesLoaded;
    },
    resetProductInventory: (state: OrdersState) => {
      state.productInventoryData = initialState.productInventoryData;
    },
    setProductInventory: (state: OrdersState, action: PayloadAction<ProductInventory[]>) => {
      productInventoryAdapter.setMany(state.productInventoryData, action.payload);
    },
    setProductInventoryProductKeys: (state: OrdersState, action: PayloadAction<string[]>) => {
      productInventoryProductKeyAdapter.setMany(state.productInventoryProductKeys, action.payload);
    },
  },
});

export const { selectById: selectProductInventoryProductKey } =
  productInventoryProductKeyAdapter.getSelectors<RootState>(
    (state: RootState) => state.orders.productInventoryProductKeys
  );

export const {
  selectAll: selectProductInventorysFromRoot,
  selectById: selectProductInventoryByIdFromRoot,
  selectIds: selectProductInventoryIdsFromRoot,
} = productInventoryAdapter.getSelectors<RootState>((state: RootState) => state.orders.productInventoryData);

const selectProductInventoryDictionary = (s: RootState) => s.orders.productInventoryData.entities;
const selectOrderProduct = (_: RootState, op: ProductData | undefined) => op;

export const selectProductIsLowInventory = createSelector(
  [selectProductInventoryDictionary, selectOrderProduct],
  (inventoryProductDict, product): IsLowInventory => getProductIsLowInventory(inventoryProductDict, product)
);

export const selectLowInventoryUoms = createSelector(
  [selectProductInventoryDictionary, selectOrderProduct],
  (inventoryProductDict, product): ProductInventory[] => getLowInventoryUoms(inventoryProductDict, product)
);
