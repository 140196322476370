import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { GetBusinessUnitLinksRequest } from '../../api/models/business-unit.models';
import { CustomerAlertRequest, CustomerAlertType } from '../../api/models/customer-alert.models';
import {
  CustomerProductPriceRequest,
  CustomerProductPriceResult,
  GetCustomerProductPriceRequest,
  GetOrderReviewCustomerProductPriceRequest,
} from '../../api/models/customer-product-price.models';
import { GetCustomerRequest } from '../../api/models/customer.models';
import { UserCustomer } from '../../api/models/site.models';
import { EditUserCustomerOrderEntrySettingRequest } from '../../api/models/user-customer.models';
import BusinessUnitLinkService from '../../api/services/business-unit-link.service';
import CustomerAlertService from '../../api/services/customer-alert.service';
import CustomerProductPriceService from '../../api/services/customer-product-price.service';
import CustomerProductService from '../../api/services/customer-product.service';
import CustomerService from '../../api/services/customer.service';
import SiteService from '../../api/services/site.service';
import UserCustomerService from '../../api/services/user-customer.service';
import { AppNotificationsCenter } from '../../helpers/lookups/AppNotificationsCenter';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { NotificationKeys } from '../../models/notifications.models';
import { getActiveOrder, orderEntrySlice } from '../orders';
import { orderReviewSlice } from '../orders/order-review.slice';
import { ordersSlice } from '../orders/orders.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import { customerSlice } from './customer.slice';
import { removeAppNotificationByKey, upsertAppNotification } from './global.thunks';
import { userSlice } from './user.slice';
import { handleNoUserInformation, setUserCustomerParSettings } from './user.thunks';
// Hooks
const customerService = CustomerService.getInstance();
const siteService = SiteService.getInstance();
const customerAlertService = CustomerAlertService.getInstance();
const customerProductService = CustomerProductService.getInstance();
const customerProductPriceService = CustomerProductPriceService.getInstance();
const businessUnitLinkService = BusinessUnitLinkService.getInstance();
const userCustomerService = UserCustomerService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

// Thunks

/**
 * Updates the selected customer to match the passed in customer
 *
 * @param customer - The customer the selected customer is being updated to match
 * @param options - Stores options like skipCartRefresh, updateLocationAllSettings and skipRequestingPermissionData values
 * @returns NULL
 */
export const updateSelectedCustomer =
  (
    customer: UserCustomer,
    options?: {
      skipCartRefresh?: boolean;
      updateLocationAllSetting?: boolean;
      skipRequestingPermissionData?: boolean;
      skipOrderDataAndAlerts?: boolean;
    }
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    // Set All Location to current customer (false) when user changes Customer
    const currentCustomer = getState().customer.selectedCustomer;
    const userSettings = getState().user.userSettingPreference;
    if (
      currentCustomer &&
      userSettings?.LocationAll &&
      customer.CustomerId.toLocaleLowerCase() !== currentCustomer.CustomerId.toLocaleLowerCase() &&
      options?.updateLocationAllSetting
    ) {
      dispatch(userSlice.actions.setUserSettingPreference({ ...userSettings, LocationAll: false }));
    }

    if (!options?.skipOrderDataAndAlerts) {
      dispatch(clearCustomerAlerts());
      dispatch(customerSlice.actions.setCustomerDeliveryDates([]));
    }
    if (!options?.skipCartRefresh) dispatch(getActiveOrder(customer.CustomerId));
    dispatch(getCustomerAlerts(customer.CustomerId));

    dispatch(customerSlice.actions.setSelectedCustomer(customer));

    if (!options?.skipRequestingPermissionData) await dispatch(getAreaFeaturePermissionSetting(customer));
  };

/**
 * Calls and stores results from GetAreaFeaturePermission API call
 *
 * @param customer - The customer whos area feature permissions are being retrieved
 * @param options - Stores options like skipCartRefresh, updateLocationAllSettings and skipRequestingPermissionData values
 * @returns NULL
 */
export const getAreaFeaturePermissionSetting =
  (customer: UserCustomer): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const { data } = await siteService.getAreaFeaturePermissionSetting(customer.CustomerId);
      if (data.IsSuccess) {
        dispatch(userSlice.actions.setAreaFeaturePermissionSetting(data.ResultObject));
      } else {
        dispatch(handleNoUserInformation());
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(handleNoUserInformation());
    }
  };

/**
 * Updates the selected customer to match the customer specified by the customer id
 *
 * @param customerId - The customer id of the customer the selected customer is being updated to match
 * @returns NULL
 */
export const updateSelectedCustomerByCustomerId =
  (
    customerId: string,
    options?: { skipCartRefresh?: boolean; updateLocationAllSetting?: boolean; skipRequestingPermissionData?: boolean }
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const customer = getState().customer.selectedCustomer;
    if (customer) {
      if (customerId.toLocaleLowerCase() !== customer.CustomerId.toLocaleLowerCase()) {
        const userSite = getState().user.userSite;
        if (userSite) {
          const newCustomer = userSite.UserCustomers.find(
            (c) => c.CustomerId.toLocaleLowerCase() === customerId.toLocaleLowerCase()
          );
          if (newCustomer) await dispatch(updateSelectedCustomer(newCustomer, options));
        }
      }
    }
  };

// /customer

/**
 * Calls and stores the GetCustomer API call
 *
 * @param customerId - The customer id of the customer the API call should use
 * @returns NULL
 */
export const getCustomerBillto =
  (customerId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (!customerId) return;

      const getCustomer: GetCustomerRequest = { customerId: customerId };

      const { data } = await customerService.getCustomer(getCustomer);
      if (data.IsSuccess) {
        dispatch(customerSlice.actions.setCustomerBillto(data.ResultObject));
      }
      // [TODO]: Handle state if IsSuccess === false
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

// Thunks

/**
 * Calls and stores the GetCustomerDeliveryDates API call
 *
 * @returns NULL
 */
export const getCustomerDeliveryDates = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  try {
    const customerId = getState().customer.selectedCustomer?.CustomerId;
    if (!customerId) return;

    const { data } = await customerService.getCustomerDeliveryDates({
      customerId: customerId,
    });

    if (data.IsSuccess) {
      dispatch(customerSlice.actions.setCustomerDeliveryDates(data.ResultObject));
    } else {
      dispatch(customerSlice.actions.setCustomerDeliveryDates([]));
    }
  } catch (error: unknown) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
    // [TODO]: Handle state if an error occurs here
  }
};

/**
 * Calls and stores the GetCustomerAlert API call
 *
 * @param customerId - The customer id used to make the API call
 * @returns NULL
 */
export const getCustomerAlerts =
  (customerId?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const _customerId = customerId || getState().customer.selectedCustomer?.CustomerId;
      if (!_customerId) return;

      const request: CustomerAlertRequest = {
        CustomerId: _customerId,
      };

      const { data } = await customerAlertService.getCustomerAlert(request);
      if (data.IsSuccess) {
        const result = data.ResultObject;
        const warningResults = result
          .filter((x) => x.CustomerAlertType === CustomerAlertType.WarningMessage)
          .map((y) => y.CustomerAlertMessage);
        const informationResults = result
          .filter((x) => x.CustomerAlertType === CustomerAlertType.InformationalMessage)
          .map((y) => y.CustomerAlertMessage);
        const systemResults = result
          .filter((x) => x.CustomerAlertType === CustomerAlertType.SystemMessage)
          .map((y) => y.CustomerAlertMessage);
        //warning and informational can upsert multiple notifications
        if (warningResults && warningResults.length) {
          dispatch(
            upsertAppNotification(
              AppNotificationsCenter.getNotificationByKey(
                NotificationKeys.CustomerAlertWarning,
                warningResults.join('\r\n')
              ),
              0
            )
          );
        } else {
          dispatch(removeAppNotificationByKey(NotificationKeys.CustomerAlertWarning));
        }
        if (informationResults && informationResults.length) {
          dispatch(
            upsertAppNotification(
              AppNotificationsCenter.getNotificationByKey(
                NotificationKeys.CustomerAlertInformation,
                informationResults.join('\r\n')
              ),
              0
            )
          );
        } else {
          dispatch(removeAppNotificationByKey(NotificationKeys.CustomerAlertInformation));
        }
        if (systemResults && systemResults.length) {
          dispatch(
            upsertAppNotification(
              AppNotificationsCenter.getNotificationByKey(NotificationKeys.CustomerAlertSystem, systemResults[0]),
              0
            )
          );
        } else {
          dispatch(removeAppNotificationByKey(NotificationKeys.CustomerAlertSystem));
        }
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const clearCustomerAlerts = (): AppThunk => (dispatch) => {
  dispatch(removeAppNotificationByKey(NotificationKeys.CustomerAlertWarning));
  dispatch(removeAppNotificationByKey(NotificationKeys.CustomerAlertInformation));
  dispatch(removeAppNotificationByKey(NotificationKeys.CustomerAlertSystem));
};

//customer product

/**
 * Calls and stores the UpdateIsCriticalItem API call
 *
 * @param productKey - The product key of the product that is having it's IsCriticalItem attribute updated
 * @param isCriticalItem - The value that IsCriticalItem should be updated to
 * @returns NULL
 */
export const setIsCriticalItem =
  (productKey: string, isCriticalItem: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const { data } = await customerProductService.updateIsCriticalItem({
        CustomerId: customerId,
        ProductKey: productKey,
        IsCriticalItem: isCriticalItem,
      });

      if (data.IsSuccess) {
        dispatch(customerSlice.actions.setIsCriticalItem(data.ResultObject));
      }
      // [TODO]: Handle state if IsSuccess === false
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

/**
 * Calls and stores the GetCustomerProductPrice API call
 *
 * @param customerProductPriceRequests - The request to use for the API call
 * @param onSuccessAction - Method to be called on success
 * @returns NULL
 */
export const getCustomerProductPrices =
  (
    customerProductPriceRequests: CustomerProductPriceRequest[],
    onSuccessAction?: (priceResults: CustomerProductPriceResult[]) => void
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const selectedCustomer = getState().customer.selectedCustomer;
      const deliveryDate = getState().orders.activeOrder?.DeliveryDate;
      const productPriceLoading = getState().orders.productPriceLoading;
      const canViewPrices = getState().user.userSite?.AreaFeaturePermissionSetting.PermissionSetting.CanViewPrices;

      if (customerProductPriceRequests.length === 0 || !selectedCustomer || !deliveryDate || !canViewPrices) return;

      const getCustomerProductPriceRequest: GetCustomerProductPriceRequest = {
        BusinessUnitKey: selectedCustomer.BusinessUnitKey,
        OperationCompanyNumber: selectedCustomer.OperationCompanyNumber,
        CustomerId: selectedCustomer.CustomerId,
        DeliveryDate: deliveryDate,
        CustomerProductPriceRequests: customerProductPriceRequests,
        IgnoreRetry: false,
      };

      dispatch(ordersSlice.actions.setProductPriceLoaded(false));

      dispatch(
        orderEntrySlice.actions.setProductPricesReload(getCustomerProductPriceRequest.CustomerProductPriceRequests)
      );

      dispatch(ordersSlice.actions.setProductPriceLoading(true));

      const {
        data: result,
        data: { ResultObject: customerProductPriceResults },
      } = await customerProductPriceService.getCustomerProductPrice(getCustomerProductPriceRequest);
      if (result.IsSuccess) {
        if (onSuccessAction) onSuccessAction(customerProductPriceResults);
      }
      dispatch(ordersSlice.actions.setProductPriceLoading(false));
      dispatch(ordersSlice.actions.setProductPriceLoaded(true));
      dispatch(orderReviewSlice.actions.setDidYouForgetProductPriceLoaded(true));
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(ordersSlice.actions.setProductPriceLoading(false));
      dispatch(ordersSlice.actions.setProductPriceLoaded(true));
      dispatch(orderReviewSlice.actions.setDidYouForgetProductPriceLoaded(true));
    }
  };

/**
 * Calls and stores the GetOrderReviewCustomerProductPrice API call
 *
 * @returns NULL
 */
export const getOrderReviewCustomerProductPrices = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  try {
    const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
    const canViewPrices = getState().user.userSite?.AreaFeaturePermissionSetting.PermissionSetting.CanViewPrices;

    if (!activeOrderId || !canViewPrices) return;

    const orderReviewCustomerProductPriceRequest: GetOrderReviewCustomerProductPriceRequest = {
      OrderEntryHeaderId: activeOrderId,
    };

    dispatch(ordersSlice.actions.setProductPriceLoading(true));
    const {
      data: result,
      data: { ResultObject: orderReviewCustomerProductPriceResults },
    } = await customerProductPriceService.getOrderReviewCustomerProductPrice(orderReviewCustomerProductPriceRequest);
    if (result.IsSuccess) {
      dispatch(orderReviewSlice.actions.setProductPrices(orderReviewCustomerProductPriceResults));
    }
    dispatch(ordersSlice.actions.setProductPriceLoading(false));
    dispatch(ordersSlice.actions.setProductPriceLoaded(true));
  } catch (error: unknown) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
    dispatch(ordersSlice.actions.setProductPriceLoading(false));
    dispatch(ordersSlice.actions.setProductPriceLoaded(true));
  }
};

/**
 * Calls and stores the GetBusinessUnitLinks API call
 *
 * @returns NULL
 */
export const getBusinessUnitLinks = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const businessUnitKey = getState().user.userSite?.BusinessUnitKey;
    if (typeof businessUnitKey === 'undefined') return;

    const getBusinessUnitLinksRequest: GetBusinessUnitLinksRequest = {
      BusinessUnitKey: businessUnitKey,
    };

    const { data: result } = await businessUnitLinkService.getBusinessUnitLinks(getBusinessUnitLinksRequest);
    if (result.IsSuccess && typeof result.ResultObject !== 'undefined') {
      dispatch(customerSlice.actions.setBusinessUnitLinks(result.ResultObject));
    }
  } catch (error: unknown) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }
};

export const editUserCustomerSettings =
  (customer: UserCustomer, showPars: boolean, parHeaderId?: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const request: EditUserCustomerOrderEntrySettingRequest = {
        UserId: customer.UserId,
        CustomerId: customer.CustomerId,
        OrderEntryShowPars: showPars,
        OrderEntryParListHeaderId: parHeaderId,
      };

      const { data: result } = await userCustomerService.editUserCustomerSettings(request);

      dispatch(
        customerSlice.actions.setSelectedCustomer({
          UserId: customer.UserId,
          UserDisplayName: customer.UserDisplayName,
          UserEmail: customer.UserEmail,
          Username: customer.Username,
          BusinessUnitKey: customer.BusinessUnitKey,
          BusinessUnitERPKey: customer.BusinessUnitERPKey,
          OperationCompanyNumber: customer.OperationCompanyNumber,
          CustomerId: customer.CustomerId,
          CustomerName: customer.CustomerName,
          CustomerAddress: customer.CustomerAddress,
          CustomerLocation: customer.CustomerLocation,
          RoleId: customer.RoleId,
          RoleName: customer.RoleName,
          UserCustomerSetting: customer.UserCustomerSetting,
          CanOnlyOrderSingleProductUnitOfMeasure: customer.CanOnlyOrderSingleProductUnitOfMeasure,
          CustomerNumber: customer.CustomerNumber,
          CustomerZip: customer.CustomerZip,
          OperationCompanyName: customer.OperationCompanyName,
          CustomerCreditTermDisplay: customer.CustomerCreditTermDisplay,
          OrderEntryShowPars: result.ResultObject.OrderEntryShowPars,
          OrderEntryParListHeaderId: result.ResultObject.OrderEntryParListHeaderId,
        })
      );
      const newCustomer = getState().customer.selectedCustomer;
      if (newCustomer) dispatch(setUserCustomerParSettings(newCustomer));
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
