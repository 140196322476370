import { Dictionary } from '@reduxjs/toolkit';
import { ProductInventory, ProductType, UnitOfMeasureType, UpdateCustomProductRequest } from '../../api';
import { CatalogProduct, UnitOfMeasureOrderQuantity } from '../../api/models/api-shared.models';
import { CustomerProductPriceRequest } from '../../api/models/customer-product-price.models';
import {
  GetOrderEntrySearchResultDetail,
  OrderEntryCategory,
  OrderEntryProduct,
} from '../../api/models/order-entry-search.models';
import { IsLowInventory } from '../../api/models/product-footer.enum';
import { ProductData } from '../../models';

export const getAllProducts = (data: GetOrderEntrySearchResultDetail): OrderEntryProduct[] => {
  return data.OrderEntryCategories?.map((val: OrderEntryCategory) => val.Products).flat();
};

export const getFilteredProductPriceRequests = (productsList: OrderEntryProduct[]): CustomerProductPriceRequest[] => {
  return productsList
    .filter(
      (p: OrderEntryProduct) =>
        p.ProductTypeEnum != ProductType.ThirdPartyVendor &&
        p.UnitOfMeasureOrderQuantities.some((uom: UnitOfMeasureOrderQuantity) => !uom.Price || uom.Price === 0)
    )
    .map((p: OrderEntryProduct | CatalogProduct) => {
      return p.UnitOfMeasureOrderQuantities.filter(
        (uom: UnitOfMeasureOrderQuantity) => !uom.Price || uom.Price === 0
      ).map(
        (uom: UnitOfMeasureOrderQuantity) =>
          ({ ProductKey: p.ProductKey, UnitOfMeasureType: uom.UnitOfMeasure } as CustomerProductPriceRequest)
      );
    })
    .flat();
};

export const getProductPriceRequests = (productsList: OrderEntryProduct[]): CustomerProductPriceRequest[] => {
  return productsList
    .map((p: OrderEntryProduct | CatalogProduct) => {
      return p.UnitOfMeasureOrderQuantities.map(
        (uom: UnitOfMeasureOrderQuantity) =>
          ({ ProductKey: p.ProductKey, UnitOfMeasureType: uom.UnitOfMeasure } as CustomerProductPriceRequest)
      );
    })
    .flat();
};

export const getTotalExtendedPrice = (product: OrderEntryProduct | CatalogProduct): number => {
  return product.UnitOfMeasureOrderQuantities.reduce(
    (extendedPrice: number, current: UnitOfMeasureOrderQuantity) => extendedPrice + (current.ExtendedPrice || 0),
    0
  );
};

export const normalizeProductKey = (productKey: string | undefined): string => {
  return productKey?.toLowerCase() ?? '-1';
};

export const sameProductKey = (a: string | undefined, b: string | undefined): boolean =>
  normalizeProductKey(a) == normalizeProductKey(b);

export const canOrderProduct = (product: OrderEntryProduct | CatalogProduct): boolean => {
  return product.CanOrder && !product.IsDiscontinued;
};

export const canOrderProductUnitOfMeasures = (product: OrderEntryProduct | CatalogProduct): boolean => {
  return !product.UnitOfMeasureOrderQuantities.some((uom) => uom.Quantity > 0 && !uom.CanOrderUom && uom.CanEditDetail);
};

export const productCanEdit = (product: OrderEntryProduct | CatalogProduct): boolean => {
  return product.UnitOfMeasureOrderQuantities.some((uom) => uom.CanEditDetail && uom.CanOrderUom && uom.Quantity > 0);
};

export const productHasQuantity = (product: OrderEntryProduct | CatalogProduct): boolean => {
  return product.UnitOfMeasureOrderQuantities.some((uom) => uom.Quantity > 0);
};

export const toQuantityString = (amount: number | undefined): string => {
  if (typeof amount === 'undefined') return '';
  return amount.toLocaleString();
};

export const showRemoveProductQuantityButton = (product?: OrderEntryProduct): boolean =>
  !!(product && productHasQuantity(product) && productCanEdit(product) && !canOrderProduct(product));

export const isProductInvalid = (p: OrderEntryProduct): boolean => {
  const hasQuantity = p?.UnitOfMeasureOrderQuantities.some((u) => u.Quantity) ?? false;

  const invalidProduct = hasQuantity && (!p.CanOrder || p.IsDiscontinued);

  return invalidProduct;
};

export const hasProductPurchaseHistory = (product?: OrderEntryProduct): boolean =>
  !!(product?.ProductLastPurchaseDate && typeof product.ProductLastPurchaseUnitOfMeasure === 'number');

export const isProductDisabled = (
  uomWithQuantity: UnitOfMeasureType | undefined,
  uom: UnitOfMeasureOrderQuantity,
  product: CatalogProduct,
  canOnlyOrderSingleProductUnitOfMeasure: boolean,
  disabled?: boolean
): boolean => {
  return (
    disabled ||
    !canOrderProduct(product) ||
    (canOnlyOrderSingleProductUnitOfMeasure &&
      uomWithQuantity !== undefined &&
      uomWithQuantity !== uom.UnitOfMeasure) ||
    !uom.CanEditDetail ||
    !uom.CanOrderUom
  );
};

export const hasProductImage = (product: CatalogProduct | ProductData | undefined): boolean => {
  return !!product?.ProductImageUrls?.[0]?.trim();
};

export const updateProductCustomAttributesFromPayload = (
  product: CatalogProduct | ProductData,
  payload: UpdateCustomProductRequest
): void => {
  const uomOrderQuantities = product.UnitOfMeasureOrderQuantities.map((oo) => {
    const uom = payload.UnitOfMeasures?.find((u) => u.UnitOfMeasure == oo.UnitOfMeasure);
    if (!uom) return oo;
    return { ...oo, ProductNumberDisplay: uom.CustomItemNumber?.trim() || oo.OrignialProductNumber };
  });

  product.CustomItemNumber = uomOrderQuantities.map((uom) => uom.ProductNumberDisplay).join(' ');
  product.CustomProductDescription = payload.CustomProductDescription?.trim();
  product.DisplayProductDescription = payload.CustomProductDescription?.trim() || product.ProductDescription;
  product.UnitOfMeasureOrderQuantities = uomOrderQuantities;
};

export const getProductIsLowInventory = (
  inventoryProductDict: Dictionary<ProductInventory>,
  product: ProductData | undefined
): IsLowInventory => {
  let result: IsLowInventory = IsLowInventory.No;
  if (product) {
    const lowInventoryUoms = getLowInventoryUoms(inventoryProductDict, product);
    if (lowInventoryUoms.length > 0) {
      if (lowInventoryUoms.some((l) => l.IsLowInventory === IsLowInventory.YesManufacturerOut)) {
        result = IsLowInventory.YesManufacturerOut;
      } else {
        result = IsLowInventory.Yes;
      }
    }
  }
  return result;
};

export const getLowInventoryUoms = (
  inventoryProductDict: Dictionary<ProductInventory>,
  product: ProductData | undefined
): ProductInventory[] => {
  const result: ProductInventory[] = [];
  if (product) {
    for (let i = 0; i < product.UnitOfMeasureOrderQuantities.length; i++) {
      const orderUom = product.UnitOfMeasureOrderQuantities[i];
      const inventoryProduct = inventoryProductDict?.[`${product.ProductKey}-${orderUom.UnitOfMeasure}`];
      if (inventoryProduct) {
        const isLowInventory = getOrderUomIsLowInventory(orderUom, inventoryProduct);
        if (isLowInventory != IsLowInventory.No) result.push(inventoryProduct);
      }
    }
  }
  return result;
};

export const getOrderUomIsLowInventory = (
  orderUom: UnitOfMeasureOrderQuantity,
  inventoryProduct: ProductInventory
): IsLowInventory => {
  let result = IsLowInventory.No;
  if (orderUom.Quantity > 0) {
    result = inventoryProduct.IsLowInventory;
    if (result === IsLowInventory.No && orderUom.Quantity > inventoryProduct.OnHand) {
      result = IsLowInventory.Yes;
    }
  }
  return result;
};
