import { openFileInCurrentWindow } from 'web/src/utilities/download-file';
import { getResolution } from 'web/src/utilities/resolution';
import {
  CatalogProduct,
  CustomerProductPriceResult,
  FileResult,
  InventorySnapshotHeader,
  InventoryWorksheetOptionDefaults,
  InventoryWorksheetRequest,
  OrderEntryProduct,
  ParListProductRequest,
  UnitOfMeasureOrderQuantity,
} from '../../api';
import { ProductListSortByType } from '../../api/models/api-shared.enums';
import {
  GetInventoryEntrySearchByProductNumberRequest,
  GetInventoryEntrySearchRequest,
} from '../../api/models/inventory-entry-search.models';
import {
  ResetInventoryCustomerProductUnitOfMeasureRequest,
  ResetInventoryCustomerProductUnitOfMeasuresRequest,
  ResetInventoryEntryHeaderRequest,
  SubmitInventoryEntryHeaderRequest,
  UpdateInventoryCustomerProductUnitOfMeasureRequest,
  UpdateInventoryEntryDetailRequest,
  UpdateInventoryEntryProductRequest,
} from '../../api/models/inventory.models';
import { ProductListSortOption } from '../../api/models/product-list-search.models';
import InventoryCustomerProductService from '../../api/services/inventory-customer-product.service';
import InventoryEntryDetailService from '../../api/services/inventory-entry-detail.service';
import InventoryEntryHeaderService from '../../api/services/inventory-entry-header.service';
import InventoryEntryProductService from '../../api/services/inventory-entry-product.service';
import InventoryEntrySearchService from '../../api/services/inventory-entry-search.service';
import InventoryReportService from '../../api/services/inventory-report.service';
import ProductListHeaderService from '../../api/services/product-list-header.service';
import ProductListSearchService from '../../api/services/product-list-search.service';
import { generateStaticId, getAnalyticsItemData, getFilteredProductPriceRequests, getTimeZone } from '../../helpers';
import { AnalyticsContext } from '../../helpers/utilities/google-analytics/google-analytics.models';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { InventoryDetailTransfer } from '../../models/inventory.models';
import { NotificationDisplayType, NotificationKeys, NotificationType } from '../../models/notifications.models';
import {
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
} from '../../models/user-activity-report.models';
import { getCustomerProductPrices } from '../common/customer.thunks';
import { globalSlice } from '../common/global.slice';
import { setErrorDialogContent, upsertAppNotification } from '../common/global.thunks';
import { logUserActivity } from '../common/user-activity.thunks';
import { selectUserPermissionSetting } from '../common/user.slice';
import { getParListDetailsByProduct } from '../par-management/par-management.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import {
  inventoryEntrySlice,
  selectInventoryEntryProductListGridDataByPage,
  selectInventoryEntryProductsByPage,
} from './inventory-entry.slice';
import { inventoryManagementSlice } from './inventory-management.slice';

const appInsightsLogger = useAppInsightsLogger();
const inventoryEntryDetailService = InventoryEntryDetailService.getInstance();

const inventoryEntryHeaderService = InventoryEntryHeaderService.getInstance();
const inventoryEntrySearchService = InventoryEntrySearchService.getInstance();
const productListHeaderService = ProductListHeaderService.getInstance();
const inventoryEntryProductService = InventoryEntryProductService.getInstance();

const inventoryCustomerProductService = InventoryCustomerProductService.getInstance();

const productListSearchService = ProductListSearchService.getInstance();
const inventoryReportService = InventoryReportService.getInstance();

export const resetInventoryEntryHeader =
  (
    resetValuesToZero: boolean,
    inventoryEntryHeaderId?: string,
    customerId?: string,
    onSuccess?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!customerId || !inventoryEntryHeaderId) return;
    dispatch(inventoryEntrySlice.actions.setIsLoadingInventoryEntryHeader(true));
    try {
      const req: ResetInventoryEntryHeaderRequest = {
        InventoryEntryHeaderId: inventoryEntryHeaderId,
        ResetValuesToZero: resetValuesToZero,
      };
      const { data } = await inventoryEntryHeaderService.resetInventoryEntryHeader(req);
      if (data.IsSuccess) {
        onSuccess?.();
        dispatch(
          logUserActivity({
            action: UserActivityAction.ClearInventoryOnHand,
            pageName: UserActivityPageName.InventoryEntry,
            actionSummary: UserActivityActionSummary.ClearInventoryOnHand,
            resolution: getResolution(),
          })
        );
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        //severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(inventoryEntrySlice.actions.setIsLoadingInventoryEntryHeader(false));
    }
  };

export const resetProductListHeaderOptionsForInventoryEntry = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(inventoryEntrySlice.actions.resetProductListHeadersLoading());
  dispatch(inventoryEntrySlice.actions.resetProductListHeaders());
};

export const resetSearchProductListInventoryEntry = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(inventoryEntrySlice.actions.resetSearchProductListInventoryEntry());
  dispatch(inventoryEntrySlice.actions.setIsLoadingProductSearchResult(true));
  dispatch(inventoryEntrySlice.actions.resetCategories());
};

export const getInventoryEntryHeader =
  (inventoryEntryHeaderId: string, refresh?: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!refresh && !getState().inventoryEntry.isLoadingInventoryEntryHeader)
        dispatch(inventoryEntrySlice.actions.setIsLoadingInventoryEntryHeader(true));

      const { data } = await inventoryEntryHeaderService.getInventoryEntryHeader({
        InventoryEntryHeaderId: inventoryEntryHeaderId,
      });

      if (data.IsSuccess) {
        dispatch(inventoryEntrySlice.actions.setInventoryEntryHeader(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventoryEntrySlice.actions.setIsLoadingInventoryEntryHeader(false));
    }
  };

export const getProductListInventoryEntrySortBy =
  (productListHeaderId?: string, successCallback?: (sortByOptions: ProductListSortOption[]) => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (!getState().inventoryEntry.apiRequest)
        dispatch(
          inventoryEntrySlice.actions.setPageLoading({
            pageIndex: 0,
            pageSize: 75,
            isLoading: true,
          })
        );

      const { data } = await productListSearchService.getProductListInventoryEntrySort(productListHeaderId);

      if (data.IsSuccess) {
        dispatch(inventoryEntrySlice.actions.setSortByOptions(data.ResultObject));
        successCallback?.(data.ResultObject);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

export const getProductListHeaderOptionsForInventory =
  (inventoryEntryHeaderId?: string, successCallback?: (productHeaderIds: string[]) => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      dispatch(inventoryEntrySlice.actions.setIsProductListHeadersLoading(true));

      const apiRequest = {
        excludeReadOnlyLists: false,
        isForOrderEntry: true,
        customerId: customerId,
        inventoryEntryHeaderId: inventoryEntryHeaderId,
      };

      const { data } = await productListHeaderService.getProductListHeaders(apiRequest);

      dispatch(inventoryEntrySlice.actions.setProductListHeaders(data.ResultObject));
      successCallback?.(data.ResultObject.map((header) => header.ProductListHeaderId));

      dispatch(inventoryEntrySlice.actions.setIsProductListHeadersLoading(false));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

export const getProductListInventoryEntrySearch =
  (
    request: {
      inventoryEntryHeaderId: string;
      productListHeaderId?: string;
      sortByType?: ProductListSortByType;
      filterText?: string;
      pageIndex?: number;
      itemsWithQuantityOnly?: boolean;
    },
    analyticsContext?: AnalyticsContext
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const productListHeaderId =
        request.productListHeaderId ?? getState().inventoryEntry.inventoryEntryHeader?.InventoryEntryHeaderId;
      const sortByType = request?.sortByType;
      //   const previousAPIRequest = getState().inventoryEntry.apiRequest;

      //const pageSize = 75;
      const pageIndex = 0; //request.pageIndex ?? 0;
      //   const skip =
      //     (request.pageIndex === undefined ||
      //     request.pageIndex === 0 ||
      //     (previousAPIRequest && request.itemsWithQuantityOnly !== previousAPIRequest.ItemsWithQuantityOnly)
      //       ? 0
      //       : getState().inventoryEntry.productSearchResult?.Skip) ?? 0;
      const filterText = request.filterText;

      if (!request.inventoryEntryHeaderId || !productListHeaderId || sortByType === undefined) {
        return;
      }

      const apiRequest: GetInventoryEntrySearchRequest = {
        InventoryEntryHeaderId: request.inventoryEntryHeaderId,
        ProductListHeaderId: productListHeaderId,
        QueryText: filterText ?? '',
        SortByType: sortByType.valueOf(),
        Skip: 0, //skip,
        //PageSize: undefined//pageSize,
        ItemsWithQuantityOnly: request.itemsWithQuantityOnly ?? false,
      };

      // Data in store is for search with different parameters (excluding skip)
      if (
        JSON.stringify({ ...apiRequest, Skip: 0 }) !==
        JSON.stringify({ ...getState().inventoryEntry.apiRequest, Skip: 0 })
      ) {
        dispatch(resetSearchProductListInventoryEntry());
      } else {
        return;
      }

      dispatch(inventoryEntrySlice.actions.setApiRequest(apiRequest));

      //   if (pageIndex > 0) {
      //     analyticsContext?.analytics?.event?.(
      //       analyticsContext.listId,
      //       'load_more',
      //       request.inventoryEntryHeaderId,
      //       pageIndex
      //     );
      //   }

      dispatch(
        inventoryEntrySlice.actions.setPageLoading({
          pageIndex: pageIndex,
          pageSize: 75,
          isLoading: true,
        })
      );

      const { data } = await inventoryEntrySearchService.getInventoryEntrySearch(apiRequest);

      if (data.IsSuccess) {
        //data.ResultObject.pageSize = pageSize;
        data.ResultObject.pageIndex = pageIndex;
        const previousIndex = pageIndex - 1;
        const previousPage =
          previousIndex >= 0 ? selectInventoryEntryProductListGridDataByPage(getState(), previousIndex) : undefined;

        dispatch(
          inventoryEntrySlice.actions.searchProductListParMaintenance({
            request: apiRequest,
            result: data.ResultObject,
            previousPage,
          })
        );

        analyticsContext?.analytics?.viewItemList?.(
          data.ResultObject.ProductListCategories.flatMap((c, index) =>
            c.Products.flatMap((p) =>
              p.Product.UnitOfMeasureOrderQuantities.map((uom) =>
                getAnalyticsItemData(
                  p.Product.ProductKey ?? '',
                  uom.UnitOfMeasure.toString(),
                  uom.Price ?? 0,
                  uom.Quantity,
                  index,
                  uom,
                  p.Product,
                  analyticsContext.listId,
                  analyticsContext.listName
                )
              )
            )
          ),
          analyticsContext.listId,
          analyticsContext.listName
        );
        const canViewPrice = selectUserPermissionSetting(getState()).CanViewInventoryPricing;
        if (canViewPrice) {
          dispatch(getInventoryEntryProductPricesByPage(pageIndex));
        }

        if (!data.ResultObject.HasLoadMore)
          analyticsContext?.analytics?.event?.(analyticsContext.listId, 'end_of_list', analyticsContext.listName);
      } else {
        dispatch(inventoryEntrySlice.actions.setApiRequest(undefined));
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
      dispatch(inventoryEntrySlice.actions.setApiRequest(undefined));
    }
  };

export const refreshProductListInventoryEntrySearchResults =
  (): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const searchReq = getState().inventoryEntry.apiRequest;
    if (searchReq) {
      const searchRequest = {
        inventoryEntryHeaderId: searchReq.InventoryEntryHeaderId,
        productListHeaderId: searchReq.ProductListHeaderId,
        sortByType: searchReq.SortByType,
        filterText: searchReq.QueryText,
        itemsWithQuantityOnly: searchReq.ItemsWithQuantityOnly,
      };
      dispatch(resetSearchProductListInventoryEntry());
      dispatch(getProductListInventoryEntrySearch(searchRequest));
    }
  };

export const updateInventoryEntryDetail =
  (
    inventoryEntryHeaderId: string,
    unitOfMeasure: number,
    productId: string,
    productKey: string,
    thirdPartyVendorProductId: string | undefined,
    quantityFull: number | undefined,
    quantityPartial: number | undefined,
    uomBackup: UnitOfMeasureOrderQuantity,
    productBusinessUnitERPKeyType: number
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().inventoryEntry.isLoadingInventoryEntryDetails)
        dispatch(inventoryEntrySlice.actions.setIsInventoryEntryDetailUpdating(true));

      // pre-emptively ui update state
      const transferDetail: InventoryDetailTransfer = {
        productId: productId,
        uom: unitOfMeasure,
        quantityFull: quantityFull,
        quantityPartial: quantityPartial,
        uomDisplay: '',
      };
      dispatch(inventoryEntrySlice.actions.updateInventoryEntryDetail(transferDetail));

      // call api
      const request: UpdateInventoryEntryDetailRequest = {
        InventoryEntryHeaderId: inventoryEntryHeaderId,
        ProductKey: productKey,
        ThirdPartyVendorProductId: thirdPartyVendorProductId,
        UnitOfMeasureType: unitOfMeasure,
        QuantityFull: quantityFull,
        QuantityPartial: quantityPartial,
        ProductBusinessUnitERPKeyType: productBusinessUnitERPKeyType,
      };

      const { data } = await inventoryEntryDetailService.updateInventoryEntryDetail(request);

      // handle failure, restore old state
      if (!data.IsSuccess) {
        transferDetail.quantityFull = uomBackup.QuantityFull;
        transferDetail.quantityPartial = uomBackup.QuantityPartial;
        dispatch(inventoryEntrySlice.actions.updateInventoryEntryDetail(transferDetail));
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      } else {
        dispatch(getInventoryEntryHeader(inventoryEntryHeaderId, true));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventoryEntrySlice.actions.setIsInventoryEntryDetailUpdating(false));
    }
  };

export const setInventoryEntryProductPrices =
  (priceResults: CustomerProductPriceResult[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(inventoryEntrySlice.actions.setProductPrices(priceResults));
  };

export const getInventoryEntryProductPricesByPage =
  (pageIndex: number): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const apiRequest = getState().inventoryEntry.apiRequest;
    const products = selectInventoryEntryProductsByPage(getState(), pageIndex);
    const filteredProducts = getFilteredProductPriceRequests(products);

    while (filteredProducts.length > 0) {
      // sanity check: don't process stale data
      if (
        JSON.stringify({ ...apiRequest, Skip: 0 }) !==
        JSON.stringify({ ...getState().inventoryEntry.apiRequest, Skip: 0 })
      ) {
        break;
      }

      await dispatch(
        getCustomerProductPrices(filteredProducts.splice(0, 142), (priceResults: CustomerProductPriceResult[]) => {
          dispatch(setInventoryEntryProductPrices(priceResults));
        })
      );
    }
  };

export const submitInventoryEntryHeader =
  (
    inventoryEntryHeaderId: string,
    name: string,
    date: string | Date,
    resetQuantities: boolean,
    onSuccess: (inventorySnapshotHeader: InventorySnapshotHeader) => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(inventoryEntrySlice.actions.setIsSubmittingInventoryEntryHeader(true));

    try {
      const submitRequest: SubmitInventoryEntryHeaderRequest = {
        InventoryEntryHeaderId: inventoryEntryHeaderId,
        InventoryHeaderName: name,
        InventoryDate: date,
        ResetQuantites: resetQuantities,
        TimeZone: getTimeZone(),
      };

      const { data } = await inventoryEntryHeaderService.submitInventoryEntryHeader(submitRequest);

      if (data.IsSuccess) {
        onSuccess(data.ResultObject);

        dispatch(
          logUserActivity({
            action: UserActivityAction.SubmitInventory,
            pageName: UserActivityPageName.InventoryEntry,
            actionSummary: UserActivityActionSummary.SubmittedInventory,
            resolution: getResolution(),
          })
        );
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventoryEntrySlice.actions.setIsSubmittingInventoryEntryHeader(false));
    }
  };

export const getInventoryEntryParListDetailsByProductByPage =
  (parListHeaderId: string, pageIndex: number): AppThunk =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const products = selectInventoryEntryProductsByPage(getState(), pageIndex);

    const requests: ParListProductRequest[] = products
      .map((product: OrderEntryProduct | CatalogProduct) => {
        return product.UnitOfMeasureOrderQuantities.map(
          (uom) => ({ ProductKey: product.ProductKey, UnitOfMeasureType: uom.UnitOfMeasure } as ParListProductRequest)
        );
      })
      .flat();

    dispatch(getParListDetailsByProduct(parListHeaderId, requests));
  };

/* Worksheets */

export const getInventoryWorksheet =
  (request: InventoryWorksheetRequest): AppThunk<Promise<FileResult | undefined>> =>
  async (dispatch: AppDispatch): Promise<FileResult | undefined> => {
    try {
      const { data } = await inventoryReportService.getInventoryWorksheet(request);
      if (data.IsSuccess) {
        return data.ResultObject;
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

export const exportInventoryWorksheet =
  (request: InventoryWorksheetRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().inventoryManagement.isInventoryReportExporting) {
      return;
    }
    try {
      dispatch(inventoryManagementSlice.actions.setInventoryReportIsExporting(true));

      dispatch(
        upsertAppNotification(
          {
            Id: generateStaticId('toastNotification', ['inventory-report-toast']),
            NotificationType: NotificationType.Information,
            NotificationDisplayType: NotificationDisplayType.Toast,
            AutoDismiss: 10,
            CanUserDismiss: true,
            Key: NotificationKeys.Toast,
            Message: 'We are preparing your download.',
          },
          0
        )
      );

      const { data } = await inventoryReportService.getInventoryWorksheet(request);
      if (data.IsSuccess) {
        openFileInCurrentWindow(data.ResultObject as FileResult);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventoryManagementSlice.actions.setInventoryReportIsExporting(false));
    }
  };

export const getInventoryWorksheetUserOptions =
  (): AppThunk<Promise<InventoryWorksheetOptionDefaults | undefined>> =>
  async (): Promise<InventoryWorksheetOptionDefaults | undefined> => {
    try {
      const { data } = await inventoryReportService.getInventoryWorksheetUserOptions();
      if (data.IsSuccess) {
        return data.ResultObject;
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }

    return Promise.resolve(undefined);
  };

/* Inventory Product Note */

export const updateInventoryEntryProduct =
  (request: UpdateInventoryEntryProductRequest, successCallback?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().inventoryEntry.isUpdatingInventoryProduct)
        dispatch(inventoryEntrySlice.actions.setIsUpdatingInventoryProduct(true));

      const { data } = await inventoryEntryProductService.updateInventoryEntryProduct(request);

      if (data.IsSuccess) {
        dispatch(inventoryEntrySlice.actions.setInventoryEntryProduct(data.ResultObject));
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventoryEntrySlice.actions.setIsUpdatingInventoryProduct(false));
    }
  };

/* Inventory Custom Pack Size */

export const resetInventoryCustomerProductUnitOfMeasure =
  (request: ResetInventoryCustomerProductUnitOfMeasureRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { data } = await inventoryCustomerProductService.resetInventoryCustomerProductUnitOfMeasure(request);

      if (data.IsSuccess) {
        dispatch(inventoryEntrySlice.actions.resetProductCustomPackSize(request));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

export const resetInventoryCustomerProductUnitOfMeasures =
  (request: ResetInventoryCustomerProductUnitOfMeasuresRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { data } = await inventoryCustomerProductService.resetInventoryCustomerProductUnitOfMeasures(request);
      if (data.IsSuccess) {
        dispatch(inventoryEntrySlice.actions.resetAllCustomPackSizes(request));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

export const updateInventoryCustomerProductUnitOfMeasure =
  (request: UpdateInventoryCustomerProductUnitOfMeasureRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { data } = await inventoryCustomerProductService.updateInventoryCustomerProductUnitOfMeasure(request);
      if (data.IsSuccess) {
        dispatch(inventoryEntrySlice.actions.setProductCustomPackSize(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

export const getInventoryEntrySearchByProductNumber =
  (
    inventoryEntryHeaderId: string,
    queryText: string,
    onSuccess?: (product: CatalogProduct) => void,
    onError?: (err: string[]) => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customer = getState().customer.selectedCustomer;
      if (!customer) return;

      const apiRequest: GetInventoryEntrySearchByProductNumberRequest = {
        InventoryEntryHeaderId: inventoryEntryHeaderId,
        QueryText: queryText,
        ProductNumberSearchOnly: true,
        IncludeInactiveProducts: true,
        Skip: 0,
        BusinessUnitKey: customer.BusinessUnitKey,
        OperationCompanyNumber: customer.OperationCompanyNumber || '',
        CustomerId: customer.CustomerId,
        PageSize: 1,
      };

      const { data } = await inventoryEntrySearchService.getInventoryEntrySearchByProductNumber(apiRequest);
      if (data.IsSuccess && data.ResultObject.CatalogProducts.length > 0) {
        onSuccess?.(data.ResultObject.CatalogProducts[0].Product);
      } else {
        onError?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };
