import {
  CatalogProduct,
  FileResult,
  GetProductDetailSalesRequest,
  ProductInformationAllergensAndAdditional,
  ProductInformationDescription,
  ProductInformationPackingAndStorage,
  ServiceResult,
} from '../models';
import ApiService from './api-service';

class ProductDetailSalesService extends ApiService {
  private static classInstance: ProductDetailSalesService;

  private constructor() {
    super('SalesProductDetail');
  }
  /*
  public requestProductNutritionalInformation(request: RequestProductNutritionalInformation): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/RequestProductNutritionalInformation`, request);
  }

  public reportIncorrectProductInformation(request: ReportIncorrectProductInformation): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/ReportIncorrectProductInformation`, request);
  }
    */

  public getProductDetail(request: GetProductDetailSalesRequest): ServiceResult<CatalogProduct> {
    return this.get(`${this.baseUrl}/GetProductDetail`, request);
  }

  public getProductInformationDescription(
    request: GetProductDetailSalesRequest
  ): ServiceResult<ProductInformationDescription> {
    return this.get(`${this.baseUrl}/GetProductInformationDescription`, request);
  }

  public getProductInformationAllergensAndAdditional(
    request: GetProductDetailSalesRequest
  ): ServiceResult<ProductInformationAllergensAndAdditional> {
    return this.get(`${this.baseUrl}/GetProductInformationAllergensAndAdditional`, request);
  }

  public getProductInformationPackingAndStorage(
    request: GetProductDetailSalesRequest
  ): ServiceResult<ProductInformationPackingAndStorage> {
    return this.get(`${this.baseUrl}/GetProductInformationPackingAndStorage`, request);
  }

  public getProductDetailReport(request: GetProductDetailSalesRequest): ServiceResult<FileResult> {
    return this.post(`${this.baseUrl}/GetProductDetailReport`, request);
  }
  /*

  public getProductDetailDocumentHeaders(
    request: GetProductDetailDocumentHeadersRequest
  ): ServiceResult<ProductDetailDocument[]> {
    return this.get(`${this.baseUrl}/GetProductDetailDocumentHeaders`, request);
  }

  public getProductDetailDocument(request: GetProductDetailDocumentRequest): ServiceResult<FileResult> {
    return this.get(`${this.baseUrl}/GetProductDetailDocument`, request);
  }
    */

  public static getInstance(): ProductDetailSalesService {
    if (!this.classInstance) {
      this.classInstance = new ProductDetailSalesService();
    }
    return this.classInstance;
  }
}

export default ProductDetailSalesService;
